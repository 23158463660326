import React from 'react'
import { POKER, POKER_GAME_DOWNLOAD, POKER_RMG } from '../../components/internal-links'
import Layout from '../../components/layout'
import SEO from '../../components/seo'
import Blogs from '../../components/Blogs/Blogs'
import { Breadcrumb } from '../../components/Breadcrumbs/Breadcrumbs'
import { generateBreadcrumbsSchema } from '../../lib/generate-schema'
import { POKER_LEARN as POKER_DOWNLOAD_LINK } from '../../components/download-links'
import RMG_PAGES from '../../rmg-tags'
import { pokerOneLinksPokerPage } from '../../components/one-links'
import {
  HOMEPAGE_BREADCRUMB_TITLE,
  POKER_BREADCRUMB_TITLE,
} from '../../utils/constnst.utils'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: POKER_BREADCRUMB_TITLE,
    url: POKER,
  },
  {
    title: 'Poker Game Download',
    url: POKER_GAME_DOWNLOAD,
  },
  {
    title: 'Poker RMG',
    url: POKER_RMG,
  },
]

const PokerRMG: React.FC = () => {
  return (
    <Layout
      showBreadcrumbs
      breadcrumbs={breadcrumbs}
      apkLink={POKER_DOWNLOAD_LINK}
      showPokerForm
      pokerOneLink={pokerOneLinksPokerPage}
    >
      <SEO
        title="Read About The Real Money Poker Apps And Websites | Mega"
        description="Find the best real money Poker apps to play. Check out our list that includes detailed information of the Poker RMG apps available for download."
        breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
      />
      <Blogs
        blogType="pokerRmg"
        tags={RMG_PAGES}
        gameCategory="Poker"
        prefix={`${POKER}${process.env.GATSBY_CMS_POKER_RMG_PREFIX}` || '/'}
        title={
          <>
            Poker - <span className="underline">RMG</span>
          </>
        }
        displayFilters={false}
      />
    </Layout>
  )
}

export default PokerRMG
